import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Start() {

    const navigate              = useNavigate();
    const {user}                = useSelector((state) => state.auth)


    useEffect(() => {

        if(!user){
            navigate("/login")
        }
    },[user, navigate])

  return (
    <div className="box-outer">   
        <div className="box-start">           
            
                <div className="main-content-arena">
                    <Outlet />
                </div>
          
        </div>
    </div>
  )
}

export default Start