import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Start from "./Pages/Start";
import Register from "./Pages/Register";
import { ToastContainer } from "react-toastify";
import BlogScreen from "./Pages/BlogScreen";
import Settings from "./Pages/Settings";


function App() {
  return (
    <>
    <Router>
      <div className="main-container">
        <Routes>
           <Route path="/login" element={<Login />}/>  
           <Route path="/register" element={<Register />}/>  
           <Route element={<Start />}>
               <Route path="/" element={<Home />}/> 
               <Route path="/home" element={<Home />}/>
               <Route path="/blog/:id" element={<BlogScreen />}/>  
               <Route path="/settings" element={<Settings />}/> 
           </Route>
        </Routes>
      </div>
    </Router>
    <ToastContainer />
    </>
  );
}

export default App;
