import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/Nav/TopNavigation'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";

function BlogScreen() {

    const params                                                    = useParams();

    const [proccessing, setProcessing]                              = useState(false);
    const [singleBlog, setSingleBlog]                               = useState([]);

    useEffect(() => {
        collectTheCurrentBlog();
    },[])

    const collectTheCurrentBlog = async () => {
        setProcessing(true);
        try{
            const singlesRes = await axios.get(CONSTANTS.WP_URL +"posts/" + params.id);
            console.log("|||||||||||||||||");
            console.log("BLOOG");
            console.log(singlesRes.data);
            setSingleBlog(singlesRes.data);

            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }
    }

    function formatThisDate(inputDate) {
        const months = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
      
        const date = new Date(inputDate);
      
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert 24hr time to 12hr format
      
        const formattedDate = `${day} ${months[monthIndex]} ${year} - ${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
      
        return formattedDate;
      }
      
  return (
    <div className="main-in-vers">
        <TopNavigation titleScreen={"News Detail"}  backStatus={false} linkText={""}/>
        <div className="content-screen">
            {
                Object.keys(singleBlog).length > 0 && (
                    <div className="info-blog">
                        <div style={{  
                                  backgroundImage: "url(" + singleBlog.cjmg_url + ")",
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat'
                                }} className="blog-imagery" >

                        </div>
                        <div className="pd10">
                            <div className="date-content">
                            {formatThisDate(singleBlog.date)}
                            </div>
                            <h3>{singleBlog.title?.rendered}</h3>
                            <div className="article-content" dangerouslySetInnerHTML={
                              { __html: singleBlog.content?.rendered}} >
                            </div>
                        </div>
                    </div>
                )
            }

            {
                proccessing && (
                    <div className="gif-div">
                        <img src={CONSTANTS.SMALL_PROCESSING} className="proccesing-image" />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default BlogScreen
