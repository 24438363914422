import React, { useEffect,useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from 'axios';
import { Link } from 'react-router-dom';

function NewsComponent() {

  const [processing, setProcessing]                     = useState(false);
  const [newsArticles, setNewsArticles]                 = useState([]);
  const [message, setMessage]                           = useState("");

  useEffect(() => {
    collectNewsArticles()
  },[])

  const collectNewsArticles = async () => {
    try{
      setProcessing(true);
      const newsRes = await axios.get(CONSTANTS.WP_URL +"posts");
     
      setNewsArticles(newsRes.data)
      if(newsRes.data.length === 0){
        setMessage("No News Found")
      }
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

    return (
        <div className='scroll-container newsContainer'>
          {
            processing && (
              <div className="pro-figure text-center">
                <img src={CONSTANTS.SMALL_PROCESSING} className="processing-figure" />
              </div>
            )
          }
          {
            newsArticles.length > 0 && (
              <div className="side-horizontal-scroller">
                  {
                    newsArticles.map((newsItem, index) => {
                      return (<div className="card item-card" key={index}>
                         <div className="bl-title">
                            <Link to={"/blog/" + newsItem.id} className="link-dark">
                            {newsItem.title.rendered}
                            </Link>
                         </div>
                         <Link to={"/blog/" + newsItem.id} className="link-dark">
                           <div style={{  
                                  backgroundImage: "url(" + newsItem.cjmg_url + ")",
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat'
                                }} className="full-image" ></div>
                           </Link>
                      </div>)
                    })
                  }
              </div>
            )
          }

          {
            message && (
              <h4>{message}</h4>
            )
          }
        </div>
      )
}
    
export default NewsComponent
    