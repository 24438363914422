import React from 'react'
import TopNavigation from '../Components/Nav/TopNavigation'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../reduxAuth/authSlice';

function Settings() {

  const navigate                     = useNavigate();
  const dispatch                     = useDispatch();
  const {user}                       = useSelector((state) => state.auth);

  const onLogOut = () => {

    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  }

  return (
    <div className="main-in-vers">
       <TopNavigation titleScreen={"Settings"}  backStatus={false} linkText={""}/>
       <div className="content-screen pd10 gray-back">
           <div className="gray-text setting-heard">PROFILE</div>
           <div className="back-white pd10">
              <p className="line">{user.name}</p>
              <p className="line">{user.surname}</p>
              <p className="line">{user.email}</p>
              <p className="line">{user.phonenumber}</p>
              <p className="line">{user.profession}</p>
           </div>
           <div className="gray-text setting-heard">SIGN OUT</div>
           <button className="btn btn-mevent-off btn-full" onClick={onLogOut}>LOG OFF</button>
       </div>
    </div>
  )
}

export default Settings
