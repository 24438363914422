import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function TopNavigation({titleScreen, backStatus, linkText}) {
  return (
    <div className="main-standard-version">
        <div className="nav-block">
            <div className="flexme">
                <div className="flex_10 nav-back">
                   <div className="">
                     <Link to={backStatus ? linkText : "/"} 
                      className="backbutton">
                        <FaArrowLeft />
                     </Link>
                   </div>
                </div>
                <div className="title-screen">{titleScreen}</div>
            </div>
        </div>
    </div>
  )
}

export default TopNavigation


//function navigationTop({status, statusLink, titlePage}) {
