import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as CONSTANTS from "../CONSTANTS";
import { FaBars, FaUsers, FaVoteYea, FaYinYang} from 'react-icons/fa'
import { useState } from 'react';
import axios from 'axios';
import NewsComponent from '../Components/Other/NewsComponent';

function Home() {

    const navigate              = useNavigate();
    const {user}                = useSelector((state) => state.auth)

    const [currentSupplier, setCurrentSupplier]           = useState([]);
    const [currentSupStats, setCurrentSupStats]           = useState([]);
    const [freshRate, setFreshRate]                       = useState(false);

    const [totalRatings, setTotalRatings]                 = useState(0);
    const [voteSum, setVoteSum]                           = useState(0);
    const [voteAverage, setVoteAverage]                   = useState(0);

    useEffect(() => {
      
        if(!user){
            navigate("/login")
        }
        collectCurrentSupplierStats();
        collectCurrentUserSupplier();
    },[user, navigate])

    const collectCurrentSupplierStats = async () => {
      try{

          const responSup = await axios.get(CONSTANTS.API_URL + "suppliers/collect/stats/sponsor/"+user.suppliercode);
          //console.log(responSup);
          if(responSup.status == 200){
            setCurrentSupStats(responSup.data);
          }

      }catch(err){
        console.log(err);
      }
    }  

    const collectCurrentUserSupplier = async () => {
      try{
          console.log(user.suppliercode);

          const responSup = await axios.get(CONSTANTS.API_URL + "suppliers/getlonesup/"+user.suppliercode);
          console.log("_____-----_____----LLLL>>>>>>");

          //console.log(responSup);
          if(responSup.status == 200){
            setCurrentSupplier(responSup.data);
            console.log(responSup.data);
            const ratingLength = responSup.data.userRatings.length;
            setTotalRatings(ratingLength);

            //Rating
            //Check if rating is empty or not
            if(responSup.data.userRatings.length > 0){
                const sumVoteCount = responSup.data.userRatings.reduce((acc, rating) => acc + parseInt(rating.votecount), 0);
                setVoteSum(sumVoteCount);

                const maxVotes = ratingLength * 5;

                const foundAverage = Math.round((sumVoteCount / maxVotes) * 100);
                setVoteAverage(foundAverage);
    
            }else {
              //This is a fresh rating
              setFreshRate(true);
              console.log("This is a fresh rating.")
            }
          }

      }catch(err){
        console.log(err);
      }
    }  

  return (
    <div className="main-standard-version pd10">
       <div className="shade-pad">
          <div className="flexme">
            <div className="flex_30 pd20">
                <div className="settings-link mgtop10">
                   <Link to="/settings" className="link-dark">
                      <FaBars />
                   </Link>
                </div>
            </div>
            <div className="flex_70 pd20">
              <div className="gray-text">
                <p className="orange-caps">HELLO {user?.name} {user?.surname}</p>
                <p className="txtSize2 thin">Welcome {user?.title} Supplier</p>
              </div>
            </div>
          </div>
          <div className="prof text-center">
                {
                  currentSupplier && (
                    <img src={currentSupplier.logo_image}  className="imge-profile img-supp"/>
                  )              
                }
            </div>
       </div>
        
      <div className="block-section">
        <h4 className="smTitle">Scan Detials</h4>
        {
          currentSupStats && (
            <div className="side-horizontal-scroller v-infomation">
              {
                currentSupStats.map((supplier, index) => {
                  return <div className="card item-card card-info-supp" key={index} style={{ backgroundImage: `url(${supplier.imgUrl})` }}>
                          <div className="card-overlay-supp">
                               <div className="in-vox-suppliers">
                                  <div className="bl-title">
                                    {supplier.eventName}                               
                                  </div>
                                  <div className="mega-scans">
                                  {supplier.total}  
                                  </div>
                                 
                            </div>
                        </div>
                  </div>
                })
              }
            </div>
          )
        }
       </div>
       <div className="block-section">
         <h4 className="smTitle">Rating</h4>
         <p> <sub>(Each rating is out of 5)</sub></p>
          {
            //voteSum
            voteAverage && (
              <div className="ratingflx flexme">
                  <div className="rat-box1">
                    <div className="rat-a-icon-item">
                      <FaUsers />
                    </div>
                    <div className="rat-a-title">
                      Total People
                    </div>
                    <div className="rat-a-amt">
                      {totalRatings}
                    </div>
                  </div>
                  <div className="rat-box1">
                    <div className="rat-a-icon-item">
                        <FaVoteYea />
                      </div>
                      <div className="rat-a-title">
                        Total Votes
                      </div>
                      <div className="rat-a-amt">
                        {voteSum}
                      </div>
                  </div>
                  <div className="rat-box1">
                      <div className="rat-a-icon-item">
                          <FaYinYang />
                        </div>
                        <div className="rat-a-title">
                          Average
                        </div>
                        <div className="rat-a-amt">
                          {voteAverage} %
                        </div>
                  </div>
              </div>
            )
          }
       </div>
       <div className="block-section">
        <h4 className="smTitle">News</h4>
          <NewsComponent />
       </div>
       
    </div>
  )
}

export default Home