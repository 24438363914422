import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './logauth.css'
import HeaderLogo from './../assets/tlc_logo.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from '../reduxAuth/authSlice';
import Spinner from '../Components/Other/Spinner';


function Login() {
    const [erEmpty, setErEmpty]                         = useState("");
    const [erMClass, setErrMClass]                      = useState("");
    const phonenumberRef                                      = useRef();

    const navigate                                            = useNavigate();
    const dispatch                                            = useDispatch();

    const {user, isLoading, isError, isSuccess, message}  = useSelector((state) => state.auth);

    useEffect(() => {
     
        if(isError){
            toast.error("Invalid Login")
        }
      
        if(isSuccess || user){
            navigate('/');
        }

          dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleLogin = async (e) => {
        e.preventDefault();
 
        const userData = {
            "phonenumber": phonenumberRef.current.value
        }        
        
        dispatch(login(userData));
    }

    if (isLoading) {
        return  <Spinner />
    }

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
            <div className="reg-header mgtop50">
                <img src={HeaderLogo} className="logo-one" alt="The Local Choice" />         
            </div>
            <div className="main-login-data">
               
                <div className="form-card ">
                    <div className="frm-area mgtop30">
                        <h4 className="title-login text-center">Supplier Login</h4>
                            <form encType="multipart/form-data">
                                <div className="form-group frg">
                                    <div className="text-form">Cell: </div>
                                    <input type="text" className="form-control ct-content matetrial-input wide100" ref={phonenumberRef} maxLength={10} placeholder="Enter Phone Number" required/>
                                </div>
                                <div className="form-group mgtop20">
                                    <button className="btn btn-mevent btn-full" onClick={handleLogin} disabled={isLoading}>Login</button>
                                </div>
                                <div className=" frg">
                                </div>
                            </form>
                            
                            <p className="mgtop20 space-flex">
                                <Link to="/register"  className="link-log-text">Supplier Sign Up?</Link>
                            </p>
                            
                    </div>
                </div>
            
            </div>
        </div>       
    </div>
  )
}

export default Login