import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './logauth.css'
import * as CONSTANTS from "../CONSTANTS";
import HeaderLogo from './../assets/tlc_logo.png';
import { toast } from 'react-toastify';
import Spinner from '../Components/Other/Spinner';
import axios from 'axios';

function Register() {
    const [erEmpty, setErEmpty]                         = useState("");
    const [erMClass, setErrMClass]                      = useState("");
    
    const distNameRef                                      = useRef();
    const distSurnameRef                                   = useRef();
    const phonenumberRef                                   = useRef();
    const distSupplierCodeRef                              = useRef();
    const jobTitleRef                                   = useRef();

    const navigate                                       = useNavigate();
    const [loadingPart, setLoading]                      = useState(false);
    
    const handleRegistration = async (e) => {
        e.preventDefault();
            setLoading(true);
        
            try{

                if(phonenumberRef.current.value.length == 10){
                    if(distSupplierCodeRef.current.value.length == 9){
                    
                            const userData = {
                                "phonenumber": phonenumberRef.current.value,
                                "name": distNameRef.current.value,
                                "surname": distSurnameRef.current.value,
                                "supplierCode": distSupplierCodeRef.current.value,
                                "profession" : jobTitleRef.current.value
                              }        
                              ///console.log(userData); 
                            const response = await axios.post(CONSTANTS.API_URL + "distributors/register/supplier", userData);
    
                            //console.log(response);
                            if(response.status == 201){
                                toast.success("Thank you for registration, you can login with your phonenumber.");
                            }else{
                                toast.error("Something went wrong, please try again later.");
                            }
                    }else {
                        toast.error("Please ensure supplier code is 9 digits")    
                    }
                  
                }else{
                    toast.error("Please ensure phone number is 10 digits")
                }
                
                setLoading(false);
            }catch(err){
                console.log(err);
                setLoading(false);
                toast.error(err.response.data)
            }
    }

    if (loadingPart) {
        return  <Spinner />
    }

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
            <div className="reg-header">
                <img src={HeaderLogo} className="logo-one" alt="Rescure Script" />         
            </div>
            <div className="main-login-data">
               
                <div className="form-card ">
                    <div className="frm-area">
                        <h4 className="title-login text-center">Supplier Register</h4>
                            <form onSubmit={handleRegistration} encType="multipart/form-data">
                            <div className="form-group frg">
                                <div className="text-form">Phone Number: </div>
                                <input 
                                    type="number" 
                                    className="form-control ct-content matetrial-input wide100" 
                                    ref={phonenumberRef} 
                                    placeholder="Phone Number" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Supplier Code : </div>
                                <input 
                                    type="number" 
                                    className="form-control ct-content matetrial-input wide100" 
                                    ref={distSupplierCodeRef} placeholder="Suppliers" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Name: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" 
                                    ref={distNameRef}  placeholder="Enter Name" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Surname: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" 
                                    ref={distSurnameRef} placeholder="Enter Surname" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Job Title: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" 
                                    ref={jobTitleRef}  placeholder="Profession" required/>
                            </div>     
     
                                <div className="form-group mgtop20">
                                    <button className="btn btn-mevent btn-full" disabled={loadingPart}>Sign Up</button>
                                </div>
                            <div className=" frg">
                            </div>
                        </form>
                            
                        <p className="mgtop20">
                            <Link to="/login" className="link-log-text">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>       
    </div>
  )
}

export default Register